import React, {useState} from 'react';
import {graphql} from "gatsby"
import {withStyles} from '@material-ui/core/styles';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LayoutInner from "../../components/layoutInner";
import * as PATHS from "../../utils/paths";
import { ArticleSnippet2 } from '../../components/ArticleSnippet2'

const styles = theme => ({
    root: {},
    heroText: {
        fontSize: 32,
        lineHeight: "48px",
        fontWeight: 400,
        marginBottom: 16
    },
    subheroText: {
        fontSize: 18,
        lineHeight: "32px",
        fontWeight: "regular",
        marginBottom: 16
    },
});

const _Page = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges.filter(p=> p.node.fields.collection === `how-to`)

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title={"3iap How-To Guides"} location={{pathname: PATHS.HOW_TO}}/>

            <LayoutInner>

                <h1>How-To Guides</h1>
                <p>These are hands-on guides for implementing ideas discussed in 3iap's writing and workshops.</p>
                {posts.filter(p=>!p.node.frontmatter.hide)
                .map(({node},i) => {
                    const title = node.frontmatter.title || node.fields.slug
                    return (<ArticleSnippet2 key={i}
                                             path={"/how-to" + node.fields.slug}
                                             title={title} node={node}/>)
                })}

            </LayoutInner>
        </Layout>
    )
}

export const Page = withStyles(styles)(_Page)
export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {fields: {collection: {eq: "how-to"}}}, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
            collection
          }
          frontmatter {
            title
            description
            readTime
            hide
            featuredImageAltText
            featuredImageBGColor
          }
        }
      }
    }
  }
`